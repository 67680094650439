var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"background-container",style:({
      backgroundImage:
        'url(' + require('@/assets/images/' + _vm.background_mobile) + ')',
      height: '100vh',
      width: '100vw',
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'hidden',
      backgroundSize: 'cover',
    }),attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex flex-column overflow-y-auto"},[_c('div',{staticClass:"content-container"},[_c('h2',{staticClass:"text-center mx-5",style:({
            color: _vm.WEBAPPPRO_TEXT_COLOR_1,
            fontSize: _vm.WEBAPPPRO_FONT_SIZE_1,
            fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_1,
            marginBottom: '10px',
            fontWeight: 'regular',
            'text-transform': 'uppercase',
          })},[_vm._v(" Modification prise en compte ")]),_c('v-card',{staticClass:"pa-2",style:({
            borderRadius: _vm.WEBAPPPRO_CARD_BORDER_ROUND,
            marginBottom: '30px',
            marginTop: '40px',
          }),attrs:{"width":"327","color":_vm.color,"variant":_vm.variant}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-card-item',[_c('v-card-title',[_c('v-row',{attrs:{"align":"left"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('svg',{attrs:{"width":"34","height":"34","viewBox":"0 0 34 34","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('rect',{attrs:{"width":"34","height":"34","rx":"8","fill":_vm.WEBAPPPRO_ICON_COLOR_INFO}}),_c('path',{attrs:{"d":"M21.2187 14.5777H24.7655C25.1776 14.5777 25.5 14.2369 25.5 13.8423V10.2733C25.5 9.86081 25.1596 9.53798 24.7655 9.53798C24.3714 9.53798 24.0311 9.87875 24.0311 10.2733V12.0309L22.9742 10.9728C19.6781 7.67276 14.2861 7.67276 10.99 10.9907C9.37777 12.5869 8.5 14.7212 8.5 16.9989C8.5 19.2766 9.37777 21.3929 10.99 23.0071C12.5843 24.6033 14.716 25.5 16.991 25.5C19.2661 25.5 21.3799 24.6212 22.9921 23.0071C24.2998 21.6978 25.1417 20.0299 25.3925 18.1826C25.4463 17.7701 25.1596 17.3934 24.7655 17.3396C24.3535 17.2679 23.9773 17.5728 23.9236 17.9674C23.7086 19.4918 23.0279 20.8549 21.9352 21.9489C19.284 24.6033 14.6802 24.6033 12.029 21.9489C10.7034 20.6217 9.98683 18.8641 9.98683 16.9989C9.98683 15.1337 10.7213 13.3581 12.029 12.0489C14.7518 9.32277 19.1944 9.32277 21.9352 12.0489L22.9921 13.107H21.2366C20.8246 13.107 20.5021 13.4478 20.5021 13.8423C20.5021 14.2369 20.8066 14.5777 21.2187 14.5777Z","fill":_vm.WEBAPPPRO_ICON_COLOR_INFO_2,"stroke":"white","stroke-width":"0.8"}})])]),_c('v-col',{style:({
                      color: _vm.WEBAPPPRO_TEXT_COLOR_2,
                      fontSize: _vm.WEBAPPPRO_FONT_SIZE_2,
                      fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_2,
                      fontWeight: 'bold',
                      'text-transform': 'uppercase',
                      'letter-spacing': '0em',
                      paddingLeft: '0px',
                      paddingRight: '0px',
                    }),attrs:{"cols":"auto"}},[_vm._v("Traitement en cours")])],1)],1),_c('div',{staticClass:"pl-5 mb-5",style:({
                  fontSize: _vm.WEBAPPPRO_FONT_SIZE_3,
                  fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_3,
                  color: _vm.WEBAPPPRO_TEXT_COLOR_2,
                  marginRight: '20px',
                })},[_vm._v(" La modification a été bien prise en compte. ")]),_c('div',{staticClass:"pl-5 mb-5",style:({
                  fontSize: _vm.WEBAPPPRO_FONT_SIZE_3,
                  fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_3,
                  color: _vm.WEBAPPPRO_TEXT_COLOR_2,
                  marginTop: '20px',
                  marginRight: '20px',
                  fontWeight: 'bold',
                })},[_vm._v(" Votre commande est en cours d'analyse par nos services. ")])],1)],1)],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }