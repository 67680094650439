<template>
  <div>
    <!-- <div :rail="rail" permanent @click="rail = false" class="accueil-drawer">
      <Accueil></Accueil>
    </div>
    <v-main>-->
    <div
      fluid
      :style="{
        backgroundImage:
          'url(' + require('@/assets/images/' + background_mobile) + ')',
        height: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'hidden',
        backgroundSize: 'cover',
      }"
      class="background-container"
    >
      <div class="d-flex flex-column overflow-y-auto">
        <div class="content-container">
          <h2
            class="text-center mx-5"
            :style="{
              color: WEBAPPPRO_TEXT_COLOR_1,
              fontSize: WEBAPPPRO_FONT_SIZE_1,
              fontFamily: WEBAPPPRO_FONT_FAMILY_1,
              marginBottom: '10px',
              fontWeight: 'regular',
              'text-transform': 'uppercase',
            }"
          >
            Modification prise en compte
          </h2>
          <v-card
            width="327"
            :style="{
              borderRadius: WEBAPPPRO_CARD_BORDER_ROUND,
              marginBottom: '30px',
              marginTop: '40px',
            }"
            :color="color"
            :variant="variant"
            class="pa-2"
          >
            <v-row align="center">
              <v-card-item>
                <v-card-title
                  ><v-row align="left">
                    <v-col cols="auto"
                      ><svg
                        width="34"
                        height="34"
                        viewBox="0 0 34 34"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="34"
                          height="34"
                          rx="8"
                          :fill="WEBAPPPRO_ICON_COLOR_INFO"
                        />
                        <path
                          d="M21.2187 14.5777H24.7655C25.1776 14.5777 25.5 14.2369 25.5 13.8423V10.2733C25.5 9.86081 25.1596 9.53798 24.7655 9.53798C24.3714 9.53798 24.0311 9.87875 24.0311 10.2733V12.0309L22.9742 10.9728C19.6781 7.67276 14.2861 7.67276 10.99 10.9907C9.37777 12.5869 8.5 14.7212 8.5 16.9989C8.5 19.2766 9.37777 21.3929 10.99 23.0071C12.5843 24.6033 14.716 25.5 16.991 25.5C19.2661 25.5 21.3799 24.6212 22.9921 23.0071C24.2998 21.6978 25.1417 20.0299 25.3925 18.1826C25.4463 17.7701 25.1596 17.3934 24.7655 17.3396C24.3535 17.2679 23.9773 17.5728 23.9236 17.9674C23.7086 19.4918 23.0279 20.8549 21.9352 21.9489C19.284 24.6033 14.6802 24.6033 12.029 21.9489C10.7034 20.6217 9.98683 18.8641 9.98683 16.9989C9.98683 15.1337 10.7213 13.3581 12.029 12.0489C14.7518 9.32277 19.1944 9.32277 21.9352 12.0489L22.9921 13.107H21.2366C20.8246 13.107 20.5021 13.4478 20.5021 13.8423C20.5021 14.2369 20.8066 14.5777 21.2187 14.5777Z"
                          :fill="WEBAPPPRO_ICON_COLOR_INFO_2"
                          stroke="white"
                          stroke-width="0.8"
                        />
                      </svg>
                    </v-col>
                    <v-col
                      cols="auto"
                      :style="{
                        color: WEBAPPPRO_TEXT_COLOR_2,
                        fontSize: WEBAPPPRO_FONT_SIZE_2,
                        fontFamily: WEBAPPPRO_FONT_FAMILY_2,
                        fontWeight: 'bold',
                        'text-transform': 'uppercase',
                        'letter-spacing': '0em',
                        paddingLeft: '0px',
                        paddingRight: '0px',
                      }"
                      >Traitement en cours</v-col
                    >
                  </v-row>
                </v-card-title>
                <div
                  class="pl-5 mb-5"
                  :style="{
                    fontSize: WEBAPPPRO_FONT_SIZE_3,
                    fontFamily: WEBAPPPRO_FONT_FAMILY_3,
                    color: WEBAPPPRO_TEXT_COLOR_2,
                    marginRight: '20px',
                  }"
                >
                  La modification a été bien prise en compte.
                </div>
                <div
                  class="pl-5 mb-5"
                  :style="{
                    fontSize: WEBAPPPRO_FONT_SIZE_3,
                    fontFamily: WEBAPPPRO_FONT_FAMILY_3,
                    color: WEBAPPPRO_TEXT_COLOR_2,
                    marginTop: '20px',
                    marginRight: '20px',
                    fontWeight: 'bold',
                  }"
                >
                  Votre commande est en cours d'analyse par nos services.
                </div>
              </v-card-item>
            </v-row>
          </v-card>
        </div>
      </div>
    </div>
    <!--</v-main>-->
  </div>
</template>
<script>
import { requestService } from '@/services/request.service'
import { localStorageService } from '@/services/localStorage.service'
//import Accueil from './Accueil.vue'

export default {
  name: 'Final',
  /*components: {
    Accueil,
  },*/
  data() {
    return {
      confEmail: '',
      email: '',
      firstName: '',
      lastName: '',
      address: '',
      errors: [],
      agenceName: '',
      externalId: '',

      styleTitle: localStorageService.getStyle(location.hostname, 'titleStyle'),
      buttonValidColor: localStorageService.getStyle(
        location.hostname,
        'buttonValidColor',
      ),
      WEBAPPPRO_TEXT_COLOR_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_TEXT_COLOR_1',
      ),
      buttonTextValidColor: localStorageService.getStyle(
        location.hostname,
        'buttonTextValidColor',
      ),
      buttonNeutralColor: localStorageService.getStyle(
        location.hostname,
        'buttonNeutralColor',
      ),
      buttonTextNeutralColor: localStorageService.getStyle(
        location.hostname,
        'buttonTextNeutralColor',
      ),
      icon: localStorageService.getStyle(location.hostname, 'icon'),
      iconWidth: localStorageService.getStyle(location.hostname, 'iconWidth'),
      color1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_COLOR_1',
      ),
      WEBAPPPRO_FONT_FAMILY_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_FAMILY_1',
      ),
      WEBAPPPRO_FONT_FAMILY_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_FAMILY_2',
      ),
      WEBAPPPRO_TEXT_COLOR_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_TEXT_COLOR_2',
      ),
      WEBAPPPRO_FONT_FAMILY_3: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_FAMILY_3',
      ),
      WEBAPPPRO_FONT_SIZE_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_SIZE_1',
      ),
      WEBAPPPRO_FONT_SIZE_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_SIZE_2',
      ),
      WEBAPPPRO_FONT_SIZE_3: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_SIZE_3',
      ),
      background_mobile: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BACKGROUND_MOBILE',
      ),
      WEBAPPPRO_CARD_BORDER_ROUND: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARD_BORDER_ROUND',
      ),
      WEBAPPPRO_ICON_COLOR_INFO: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_ICON_COLOR_INFO',
      ),
      isClicked: false,
      WEBAPPPRO_ICON_COLOR_INFO_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_ICON_COLOR_INFO_2',
      ),
      WEBAPPPRO_ICON_COLOR_WARNING_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_ICON_COLOR_WARNING_2',
      ),
    }
  },
  mounted() {
    if (!localStorageService.getValue('shortliveApiKey')) {
      this.$router.push({ name: 'NotAuthorized' })
      return
    }
    const uuid = localStorageService.getValue('partner_uid')
    if (
      uuid !== undefined &&
      uuid !== null &&
      !localStorageService.getValue('logo')
    ) {
      requestService.get('/partner/uuid/' + uuid).then(response => {
        if (response.data.success) {
          this.agenceName = response.data.name
          if (response.data.logo !== null) this.icon = response.data.logo
          localStorageService.setObject(
            'logo',
            response.data.logo !== null ? response.data.logo : undefined,
          )
          localStorageService.setObject(
            'primaryColor',
            response.data.primaryColor !== null
              ? response.data.primaryColor
              : undefined,
          )
          localStorageService.setObject(
            'secondaryColor',
            response.data.secondaryColor !== null
              ? response.data.secondaryColor
              : undefined,
          )
        }
      })
    }
    localStorageService.clearAll()
  },
}
</script>
<style>
.background-container {
  /*background-size: cover;*/
  height: 100vh !important;
  width: 100vw !important;
  display: flex;
}
.rounded-bottom {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-bottom: 0px;
  background-color: #ffffff;
}
.accueil-drawer {
  width: 300px; /* Ajustez la largeur du drawer selon vos besoins */
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: auto;
}
.photo-icon {
  margin: auto;
  width: 17px;
  height: 12px;
}
.text-align-left {
  display: inline-block;
  vertical-align: middle;
}
.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
}
</style>
